<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Actualité') }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.title"></span>
                </h1>
                <p v-if="$route.meta.lang && $route.meta.lang == 'en'" class="small-text text" data-inview="fadeInUp" data-delay="400">
                    {{ content.postDate_en }}
                </p>
                <p v-else class="small-text text" data-inview="fadeInUp" data-delay="400">
                    {{ content.postDate }}
                </p>
            </div>
        </section>

        <!-- Full img -->
        <section class="full-img" v-if="pageIsLoaded" data-inview="revealRight" data-delay="600">
            <img :src="content.imageSimple1.url" :alt="content.imageSimple1.titre" />
        </section>

        <section class="nwysiwyg" v-if="pageIsLoaded" v-html="content.descriptionHtml"></section>

        <!-- Articles grid -->
        <section class="articles-grid" v-if="pageIsLoaded" data-inview="fadeInUp" data-delay="600">
            <h3 class="title other-title small">{{ $t('Autres actualités') }}</h3>
            <div class="grid">
                <router-link
                    :to="item.page.jsonUrl"
                    class="article-thumb"
                    :title="item.page.title"
                    v-for="(item, i) in pageResults"
                    :key="i"
                >
                    <div class="img-wrap">
                        <img :src="item.page.imageSimple1.url" :alt="item.page.imageSimple1.titre" />
                    </div>
                    <div class="content">
                        <h2 class="regular-text title bold">{{ item.page.title }}</h2>
                        <p class="small-text text">
                            {{ item.page.postDate }}
                        </p>
                    </div>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// import axios from 'axios'
// import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

// import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {

        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.lightboxelement = GLightbox
                        // GLightbox()

                        setTimeout(() => {
                            // // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        pageResults() {
            const articles = this.globals.actualitesEntry.data.filter((item) => {
                return item.page.id !== this.content.id
            })

            // limit 3
            return articles.slice(0, 3)
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
    },
}
</script>

<style lang="scss" scoped>
.hero_content {
    height: auto;

    .data {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 10vw 5vw;
        padding-bottom: 45px;
        margin-bottom: 0;

        .title {
            padding-top: 0px;
            padding-bottom: 15px;
        }

        .small-text {
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #1d1d1d;
            line-height: 1;
            font-size: 14px;
            color: #656263;
        }

    }
}

.full-img {
    position: relative;
    overflow: hidden;
    padding-bottom: 75px;

    @media screen and (max-width: 850px) {
        padding-bottom: 35px;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 580px;
        object-fit: cover;
    }
}

.other-title {
    font-family: Barlow, sans-serif;
    font-weight: 600;
    color: #1d1d1d;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 1.2;
    padding-bottom: 30px;
    text-transform: none;

    @media screen and (max-width: 850px) {
        padding-bottom: 15px;
    }
}

</style>
